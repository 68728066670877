import 'react-medium-image-zoom/dist/styles.css'

import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Zoom } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import ImageZoom from 'react-medium-image-zoom'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
}

export const Hole = memo(function Hole({ description, image, title }: Props) {
  return (
    <Container dial={4} row wrap>
      <LeftSide>
        {image ? (
          <>
            <ImageZoom>
              <Image {...image} />
            </ImageZoom>

            <Zoom />
          </>
        ) : (
          <Image alt="" src="/hole-placeholder.png" />
        )}
      </LeftSide>

      <RightSide dial={4}>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 3.75rem;
  &:first-of-type {
    margin-top: 0;
  }
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
        padding-right: 0;
        padding-left: 3rem;
      }
    }
  }

  @media (max-width: 767px) {
    margin-top: 5rem;
    &:nth-of-type(even) {
      > div {
        &:first-of-type {
          order: 1;
          padding-left: 0;
        }
        &:last-of-type {
          order: 2;
        }
      }
    }
  }
`

const LeftSide = styled.div`
  width: 18rem;
  height: 31.25rem;
  padding-right: 3rem;
  position: relative;

  img {
    width: 18rem;
    height: auto;
  }

  > svg {
    width: auto;
    height: 1rem;
    fill: none;
    position: absolute;
    bottom: 0;
    left: 0;
    stroke: ${({ theme }) => theme.colors.variants.secondaryLight};
    stroke-width: 1.5;
    z-index: 2;
  }

  [data-rmiz-btn-unzoom] {
    display: none !important;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 18.75rem;
    padding-right: 0;
    text-align: center;

    img {
      width: auto;
      height: 18.75rem;
    }
  }
`

const RightSide = styled(FlexBox)`
  border-left: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight1};
  flex: 1;
  padding: 0 0 4.0625rem 2.8125rem;

  @media (max-width: 767px) {
    flex: auto;
    border: none;
    margin-top: 2.5rem;
    padding: 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  letter-spacing: 0.1875rem;
  line-height: 2.5rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 1.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 1.875rem;

  @media (max-width: 767px) {
    margin-top: 1.25rem;
  }
`
