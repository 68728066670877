import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Hole, Props as HoleProps } from './Hole'

export interface Props {
  holes: HoleProps[]
}

export const Holes = memo(function Holes({ holes }: Props) {
  if (holes.length < 1) {
    return null
  }

  return (
    <Container>
      {holes.map((item, index) => (
        <Hole key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 8.125rem 11.944vw 12.5rem;

  @media (max-width: 1199px) {
    padding: 5.625rem 2rem;
  }

  @media (max-width: 767px) {
    padding-top: 3.75rem;
  }
`
